import React, { FC } from 'react';

import Container from '../../../UI/Container/Container';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import Typography from '../../../UI/Typography/Typography';
import MediaAboutUsSlider from '../../../sliders/MediaAboutUsSlider/MediaAboutUsSlider';

import TestimonialsSectionQuotesSlider from '../TestimonialsSectionQuotesSlider/TestimonialsSectionQuotesSlider';

import { quotes } from './quotes';

import * as styles from './TestimonialsSection.module.scss';

const TestimonialsSection: FC = () => (
    <Section className={styles.root}>
        <Container className={styles.container}>
            <Stack className={styles.header} spacing={32}>
                <Typography variant="display" responsive align="center">
                    Don’t just take our word for it
                </Typography>
                <Typography variant="leadParagraph" responsive align="center">
                    Here’s what the community has been saying
                </Typography>
                <div className={styles.quotesSliderWrapper}>
                    <TestimonialsSectionQuotesSlider quotes={quotes} />
                </div>
            </Stack>
            <Stack spacing={32} fullWidth>
                <Typography variant="leadParagraph" responsive align="center">
                    You may know us from...
                </Typography>
                <MediaAboutUsSlider />
            </Stack>
        </Container>
    </Section>
);

export default TestimonialsSection;
