import React, { FC, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { isSafari } from '../../../../utils';
import placeholderImageSrc from '../../../../images/components/pages/index/SeeYourImpactSectionCharityVideo/placeholder.png';

import * as styles from './SeeYourImpactSectionCharityVideo.module.scss';

export interface SeeYourImpactSectionCharityVideoProps {
    src: string;
    className?: string;
}

const SeeYourImpactSectionCharityVideo: FC<
    SeeYourImpactSectionCharityVideoProps
> = ({ className, src }) => {
    // https://medium.com/@BoltAssaults/autoplay-muted-html5-video-safari-ios-10-in-react-673ae50ba1f5
    const videoWrapperRef = useRef<HTMLDivElement>(null);
    const videoHTML = `<video class="${styles.video}" playsinline autoplay loop muted src="${src}" />`;

    useEffect(() => {
        if (videoWrapperRef.current) {
            videoWrapperRef.current.innerHTML = videoHTML;
            if (isSafari()) {
                const player = videoWrapperRef.current
                    .children[0] as unknown as {
                    controls: boolean;
                    playsinline: boolean;
                    muted: boolean;
                    setAttribute: (attr: string, value: unknown) => void;
                    autoplay: boolean;
                    play: () => void;
                };

                if (player) {
                    player.controls = false;
                    player.playsinline = true;
                    player.muted = true;
                    player.setAttribute('muted', '');
                    player.autoplay = true;

                    setTimeout(() => {
                        player.play();
                    }, 0);
                }
            }
        }
    }, [videoHTML]);

    return (
        <div className={classnames(styles.root, className)}>
            <div className={styles.videoWrapper} ref={videoWrapperRef} />
            <img
                className={styles.placeholder}
                alt=""
                src={placeholderImageSrc}
            />
        </div>
    );
};

export default SeeYourImpactSectionCharityVideo;
