import React, { FC } from 'react';

import { Charity } from '../../../../../types/global';
import Slider, { Slide } from '../../../UI/Slider/Slider';
import CharityCard from '../../../charity/CharityCard/CharityCard';

import * as styles from './CharitiesSectionCharitySlider.module.scss';

export interface CharitiesSectionCharitiesSliderProps {
    charities: Charity[];
}

const CharitiesSectionCharitiesSlider: FC<
    CharitiesSectionCharitiesSliderProps
> = ({ charities }) => {
    const slides = charities.map(
        ({ id, pageRoute, headline, name, logo, cover, tags, campaign }) => (
            <Slide key={id} className={styles.slide}>
                <CharityCard
                    name={name}
                    headline={headline}
                    cover={cover}
                    logo={logo}
                    pageRoute={pageRoute}
                    tags={tags}
                    campaign={campaign}
                />
            </Slide>
        ),
    );

    return (
        <Slider
            className={styles.root}
            slidesPerView="auto"
            centeredSlides
            centeredSlidesBounds
            centerInsufficientSlides
            spaceBetween={32}>
            {slides}
        </Slider>
    );
};

export default CharitiesSectionCharitiesSlider;
