import React, { FC } from 'react';
import Button from '../../../UI/Button/Button';

import { headerHeight } from '../../../../css/variables';
import Section from '../../../UI/Section/Section';
import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import BenefitsSectionBenefitsList from '../BenefitsSectionBenefitsList/BenefitsSectionBenefitsList';

import { videoSrc } from './constants';
import * as styles from './BenefitsSection.module.scss';

import videoPreviewImage from '../../../../images/components/pages/index/BenefitsSection/video-preview.jpg';
import SignUpButton from '../../../UI/SignUpButton/SignUpButton';

export interface BenefitsSectionProps {
    nextSectionElementRef: React.RefObject<HTMLElement>;
}

const BenefitsSection: FC<BenefitsSectionProps> = ({
    nextSectionElementRef,
}) => {
    const onTellMeMoreButtonClick = () => {
        const nextSectionElement = nextSectionElementRef.current;
        if (nextSectionElement) {
            const nextSectionPosition =
                nextSectionElement.getBoundingClientRect().top;
            const offsetPosition =
                nextSectionPosition + window.pageYOffset - headerHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Section className={styles.root} label="benefits section">
            <Container className={styles.container}>
                <Typography
                    className={styles.mobileHeader}
                    variant="display"
                    responsive
                    align="center">
                    Flexible, feel-good giving
                </Typography>
                <div className={styles.content}>
                    <div className={styles.videoWrapper}>
                        <video
                            className={styles.video}
                            controls
                            poster={videoPreviewImage}>
                            <source src={videoSrc} />
                        </video>
                    </div>
                    <div className={styles.benefits}>
                        <Typography
                            className={styles.desktopHeader}
                            variant="display"
                            responsive>
                            Flexible,
                            <br />
                            feel-good giving
                        </Typography>
                        <BenefitsSectionBenefitsList
                            className={styles.benefitsList}
                        />
                        <div className={styles.CTAs}>
                            <SignUpButton size="medium" />
                            <Button
                                className={styles.tellMeMoreButton}
                                color="softGrey"
                                onClick={onTellMeMoreButtonClick}>
                                Tell me more
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default BenefitsSection;
