// extracted by mini-css-extract-plugin
export var CTAs = "BenefitsSection-module--CTAs--dOLd4";
export var benefits = "BenefitsSection-module--benefits--2Rdg3";
export var benefitsList = "BenefitsSection-module--benefitsList--R5EQ2";
export var container = "BenefitsSection-module--container--Bn2st";
export var content = "BenefitsSection-module--content--lCMMK";
export var desktopHeader = "BenefitsSection-module--desktopHeader--P-A7e";
export var mobileHeader = "BenefitsSection-module--mobileHeader--wS172";
export var root = "BenefitsSection-module--root--tLFrK";
export var tellMeMoreButton = "BenefitsSection-module--tellMeMoreButton--v8Dne";
export var video = "BenefitsSection-module--video--nFzHt";
export var videoWrapper = "BenefitsSection-module--videoWrapper--w8fvS";