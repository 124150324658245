import React, { FC } from 'react';
import { navigate } from 'gatsby';

import { useCharity } from '../../../../api/charity';
import { CharityID } from '../../../../utils/constants';
import Button from '../../../UI/Button/Button';
import Container from '../../../UI/Container/Container';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import Typography from '../../../UI/Typography/Typography';

import * as styles from './CharitiesSection.module.scss';
import CharitiesSectionCharitySlider from '../CharitiesSectionCharitySlider/CharitiesSectionCharitySlider';

const CharitiesSection: FC = () => {
    const GreatOrmondStreetHospitalCharity = useCharity(
        CharityID.GreatOrmondStreetHospital,
    ).data;
    const ActionAidCharity = useCharity(CharityID.ActionAid).data;
    const BlueCrossCharity = useCharity(CharityID.BlueCross).data;
    const NewHopeCharity = useCharity(CharityID.NewHope).data;

    const charities =
        GreatOrmondStreetHospitalCharity &&
        ActionAidCharity &&
        BlueCrossCharity &&
        NewHopeCharity
            ? [
                  GreatOrmondStreetHospitalCharity,
                  ActionAidCharity,
                  BlueCrossCharity,
                  NewHopeCharity,
              ]
            : undefined;

    const handleSeeAllCharitiesButtonClick = () => {
        navigate('/charities/');
    };

    return (
        <Section className={styles.root} label="charities section">
            <Stack className={styles.content} spacing={32}>
                <Container className={styles.container}>
                    <Stack className={styles.header} spacing={32}>
                        <Typography variant="display" responsive align="center">
                            Get behind a wonderful charity
                        </Typography>
                        <Typography
                            variant="leadParagraph"
                            responsive
                            align="center">
                            Find a cause that matters to you
                        </Typography>
                        <Button
                            size="big"
                            onClick={handleSeeAllCharitiesButtonClick}>
                            See all charities
                        </Button>
                    </Stack>
                </Container>
                {charities && (
                    <CharitiesSectionCharitySlider charities={charities} />
                )}
            </Stack>
        </Section>
    );
};

export default CharitiesSection;
