import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import useBreakpoints from '../../../../hooks/useBreakpoints';
import Button from '../../../UI/Button/Button';
import Emoji from '../../../UI/Emoji/Emoji';
import RegistrationModalWrapper from '../../../modals/RegistrationModalWrapper/RegistrationModalWrapper';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';

import { highQualityVideoSrc, lowQualityVideoSrc } from './constants';
import * as styles from './VideoSection.module.scss';
import VideoSectionVideo from '../VideoSectionVideo/VideoSectionVideo';

const mobileBackgroundImageSrc =
    '../../../../images/components/pages/index/VideoSection/background-mobile.png';
const desktopBackgroundImageSrc =
    '../../../../images/components/pages/index/VideoSection/background-desktop.png';

const VideoSection: FC = () => {
    const { isMobile } = useBreakpoints();
    const videoSrc = isMobile ? lowQualityVideoSrc : highQualityVideoSrc;

    return (
        <Section
            className={styles.root}
            MobileBackgroundImage={
                <StaticImage alt="" src={mobileBackgroundImageSrc} />
            }
            DesktopBackgroundImage={
                <StaticImage alt="" src={desktopBackgroundImageSrc} />
            }
            label="video section">
            <VideoSectionVideo className={styles.video} src={videoSrc} />
            <div className={styles.content}>
                <Typography
                    className={styles.title}
                    variant="display"
                    color="white"
                    responsive>
                    Imagine if everyone in the UK donated a few pennies every
                    day
                </Typography>
                <Typography
                    className={styles.subtitle}
                    variant="leadParagraph"
                    color="white"
                    responsive>
                    Together, we can do so much good
                </Typography>
                <RegistrationModalWrapper>
                    {({ open }) => (
                        <Button size="big" color="white" onClick={open}>
                            Count me in{' '}
                            <Emoji
                                symbol="✋🏿"
                                label="the raised hand dark skin tone emoji"
                            />
                        </Button>
                    )}
                </RegistrationModalWrapper>
            </div>
        </Section>
    );
};

export default VideoSection;
