import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SEO from '../../../layout/SEO';

const HomeSEO: FC = () => {
    const data = useStaticQuery(graphql`
        {
            currentBuildDate {
                currentDate
            }
        }
    `);

    const schemaData = {
        '@context': 'https://schema.org',
        '@graph': [
            {
                '@type': 'Organization',
                '@id': 'https://www.joinripples.org/#organization',
                name: 'Ripples',
                url: 'https://www.joinripples.org/',
                sameAs: [],
                logo: {
                    '@type': 'ImageObject',
                    '@id': 'https://www.joinripples.org/#logo',
                    url: 'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/images/ripples-icon-white-bg.png',
                    width: 500,
                    height: 500,
                    caption: 'Ripples',
                },
                image: {
                    '@id': 'https://www.joinripples.org/#logo',
                },
            },
            {
                '@type': 'WebSite',
                '@id': 'https://www.joinripples.org/#website',
                url: 'https://www.joinripples.org/',
                name: 'Ripples',
                description: 'Donate pennies as you spend',
                publisher: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
            },
            {
                '@type': 'WebPage',
                '@id': 'https://www.joinripples.org/#webpage',
                url: 'https://www.joinripples.org/',
                inLanguage: 'en-GB',
                name: 'Ripples - Donate pennies as you spend',
                isPartOf: {
                    '@id': 'https://www.joinripples.org/#website',
                },
                about: {
                    '@id': 'https://www.joinripples.org/#organization',
                },
                datePublished: '2019-09-10T22:47:00+00:00',
                dateModified: data.currentBuildDate.currentDate,
            },
        ],
    };

    return (
        <SEO
            title="Home"
            path="/"
            schemaData={[schemaData]}
            description={undefined}
            keywords={undefined}
            image={undefined}
            redirect={undefined}
        />
    );
};

export default HomeSEO;
