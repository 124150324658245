import rainforestTrustNotificationImageSrc from '../../../../images/components/pages/index/SeeYourImpactSectionCharityNotification/rainforest-trust-notification.png';
import actionAidUKNotificationImageSrc from '../../../../images/components/pages/index/SeeYourImpactSectionCharityNotification/actionaid-uk-notification.png';
import newHopeHomelessnessPreventionNotificationImageSrc from '../../../../images/components/pages/index/SeeYourImpactSectionCharityNotification/new-hope-homelessness-prevention-notification.png';
import theRossendaleTrustNotificationImage from '../../../../images/components/pages/index/SeeYourImpactSectionCharityNotification/the-rossendale-trust-notification.png';

export const charities = [
    {
        name: 'Homelessness Prevention',
        emoji: '🏠',
        videoSrc:
            'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/videos/Impact+Feed+Example+-+New+Hope+-+%C2%A32.52+-+new+background.mov',
        notificationImageSrc: newHopeHomelessnessPreventionNotificationImageSrc,
    },
    {
        name: 'ActionAid UK',
        emoji: '❤️',
        videoSrc:
            'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/videos/Impact+Feed+Example+-+ActionAid+-+%C2%A35.07+-+new+background.mov',
        notificationImageSrc: actionAidUKNotificationImageSrc,
    },
    {
        name: 'Rainforest Trust',
        emoji: '🌳',
        videoSrc:
            'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/videos/Impact+Feed+Example+-+Rainforest+Trust+UK+-+%C2%A32.89+-+new+background.mov',
        notificationImageSrc: rainforestTrustNotificationImageSrc,
    },
    {
        name: 'The Rossendale Trust',
        emoji: '🤗',
        videoSrc:
            'https://roundups-marketing-site.s3.eu-west-1.amazonaws.com/videos/Impact+Feed+Example+-+The+Rossendale+Trust+-+%C2%A31.43+-+new+background.mov',
        notificationImageSrc: theRossendaleTrustNotificationImage,
    },
];
