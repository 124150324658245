import React, { FC, useState } from 'react';

import * as styles from './VideoSection.module.scss';
import { videoSrc } from './constants';

import Modal from '../../../UI/Modal/Modal';

import playButton from '../../../../images/components/pages/index/HeroSection/play-button.png';

const VideoModal: FC = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <Modal
                contentLabel="Ripples journey video modal"
                isOpen={isOpen}
                shouldCloseOnOverlayClick
                onRequestClose={(e) => {
                    e.stopPropagation();
                    setOpen(false);
                }}
                contentClassName={styles.content}>
                <video
                    className={styles.video}
                    controls
                    // poster={videoPreviewImage}
                >
                    <source src={videoSrc} />
                </video>
            </Modal>

            <button
                type="button"
                className={styles.playButton}
                onClick={() => setOpen(true)}>
                <img
                    className={styles.playButtonImage}
                    src={playButton}
                    alt="Play Ripples explainer video"
                />
            </button>
        </>
    );
};

export default VideoModal;
