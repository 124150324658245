import React, { FC } from 'react';

import Container from '../../../UI/Container/Container';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import Stack from '../../../UI/Stack/Stack';
import SignUpButton from '../../../UI/SignUpButton/SignUpButton';

import * as styles from './ComeAndMakeSection.module.scss';

import backgroundSrc from '../../../../images/components/pages/index/ComeAndMakeSection/background.webp';

const ComeAndMakeSection: FC = () => (
    <Section className={styles.root}>
        <Container className={styles.container}>
            <Stack className={styles.content} spacing={32}>
                <Typography variant="display" responsive align="center">
                    Come and make a difference with us
                </Typography>
                <SignUpButton />
            </Stack>
        </Container>
        <div className={styles.backgroundWrapper}>
            <img className={styles.background} alt="" src={backgroundSrc} />
        </div>
    </Section>
);

export default ComeAndMakeSection;
