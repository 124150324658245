import React, { FC } from 'react';

import Slider, { Slide } from '../../../UI/Slider/Slider';
import Stack from '../../../UI/Stack/Stack';
import Typography from '../../../UI/Typography/Typography';

import * as styles from './TestimonialsSectionQuotesSlider.module.scss';

export interface TestimonialsSectionQuotesSliderProps {
    quotes: {
        quote: string;
        author: string;
        authorPhotoSrc: string;
        charityName: string;
        charityLogoSrc: string;
    }[];
}

const TestimonialsSectionQuotesSlider: FC<
    TestimonialsSectionQuotesSliderProps
> = ({ quotes }) => {
    const slides = quotes.map(
        ({ quote, author, authorPhotoSrc, charityName, charityLogoSrc }) => (
            <Slide key={charityName} className={styles.slide}>
                <Stack
                    className={styles.slideContent}
                    spacing={16}
                    align="left">
                    <Typography className={styles.quote} variant="h3">
                        “{quote}”
                    </Typography>
                    <Stack direction="row" spacing={16} valign="center">
                        <div className={styles.images}>
                            <img
                                className={styles.charityLogo}
                                alt={charityName}
                                src={charityLogoSrc}
                            />
                            <img
                                className={styles.authorImage}
                                alt={author}
                                src={authorPhotoSrc}
                            />
                        </div>
                        <Typography variant="caption">
                            {author}
                            <br />
                            Supporting {charityName}
                        </Typography>
                    </Stack>
                </Stack>
            </Slide>
        ),
    );

    return (
        <Slider
            className={styles.root}
            effect="fade"
            fadeEffect={{
                crossFade: true,
            }}
            pagination={{
                enabled: true,
            }}
            autoplay={{ delay: 7000 }}>
            {slides}
        </Slider>
    );
};

export default TestimonialsSectionQuotesSlider;
