import React, { FC } from 'react';

import * as styles from './HeroSection.module.scss';
import VideoModal from './VideoModal';

import AppDownloadBadge from '../../../UI/AppDownloadBadge/AppDownloadBadge';
import Container from '../../../UI/Container/Container';
import Emoji from '../../../UI/Emoji/Emoji';
import Typography from '../../../UI/Typography/Typography';
import Section from '../../../UI/Section/Section';
import SignUpButton from '../../../UI/SignUpButton/SignUpButton';

import journey from '../../../../images/components/pages/index/HeroSection/ripples-journey.png';
import downArrow from '../../../../images/components/pages/index/HeroSection/down-arrow.png';

const HeroSection: FC = () => (
    <Section className={styles.root} label="hero section">
        <Container className={styles.container}>
            <div className={styles.grid}>
                <div className={styles.titleWrapper}>
                    <Typography
                        className={styles.title}
                        variant="display"
                        color="white"
                        responsive>
                        Donate pennies by rounding up your card payments every
                        time you spend
                    </Typography>
                </div>
                <div className={styles.imageContainer}>
                    <img
                        className={styles.journeyImage}
                        src={journey}
                        alt="Ripples journey"
                    />
                    <VideoModal />
                </div>
                <div className={styles.ctas}>
                    <Typography
                        className={styles.subtitle}
                        variant="leadParagraph"
                        color="white"
                        responsive>
                        Together we can make a huge{' '}
                        <span className={styles.nowrap}>
                            difference <Emoji symbol="💛" label="heart emoji" />
                        </span>
                    </Typography>
                    <SignUpButton />
                    <div className={styles.appDownloadBadges}>
                        <AppDownloadBadge platform="ios" />
                        <AppDownloadBadge platform="android" />
                    </div>
                </div>
            </div>
        </Container>

        <img
            className={styles.arrowImage}
            src={downArrow}
            alt="Scroll down for more content"
        />
    </Section>
);

export default HeroSection;
