import lewisPhotoSrc from '../../../../images/components/pages/index/TestimonialsSection/lewis.webp';
import susannePhotoSrc from '../../../../images/components/pages/index/TestimonialsSection/susanne.webp';
import yasminPhotoSrc from '../../../../images/components/pages/index/TestimonialsSection/yasmin.webp';

import stokenchurchDogRescueLogoSrc from '../../../../images/components/pages/index/TestimonialsSection/stokenchurch-dog-rescue.webp';
import theRossendaleTrustLogoSrc from '../../../../images/components/pages/index/TestimonialsSection/the-rossendale-trust.webp';
import newHopeLogoSrc from '../../../../images/components/pages/index/TestimonialsSection/new-hope.webp';

export const quotes: {
    quote: string;
    author: string;
    authorPhotoSrc: string;
    charityName: string;
    charityLogoSrc: string;
}[] = [
    {
        quote: 'It puts money you won’t miss to good use in an effortless way.',
        author: 'Lewis Kinge',
        authorPhotoSrc: lewisPhotoSrc,
        charityName: 'Stokenchurch Dog Rescue',
        charityLogoSrc: stokenchurchDogRescueLogoSrc,
    },
    {
        quote: 'We get so much junk notifications these days, it’s just really nice to get something quick that makes you feel good.',
        author: 'Susanne Tidswell',
        authorPhotoSrc: susannePhotoSrc,
        charityName: 'The Rossendale Trust',
        charityLogoSrc: theRossendaleTrustLogoSrc,
    },
    {
        quote: 'Ripples makes me feel proud! It’s really good to see what the money you’re  providing is specifically doing.',
        author: 'Yasmin Lloyd',
        authorPhotoSrc: yasminPhotoSrc,
        charityName: 'New Hope',
        charityLogoSrc: newHopeLogoSrc,
    },
];
