import React, { FC, useRef } from 'react';
import classnames from 'classnames';

import { uniqueId } from '../../../../utils';
import Emoji from '../../../UI/Emoji/Emoji';
import Typography from '../../../UI/Typography/Typography';

import * as styles from './SeeYourImpactSectionCharitySelect.module.scss';

export interface SeeYourImpactSectionCharitySelectProps {
    charities: {
        name: string;
        emoji: string;
    }[];
    selectedCharityId: number;
    setSelectedCharityId: (charityId: number) => void;
}

const SeeYourImpactSectionCharitySelect: FC<
    SeeYourImpactSectionCharitySelectProps
> = ({ charities, selectedCharityId, setSelectedCharityId }) => {
    const { current: selectCommonId } = useRef(
        uniqueId(`see-your-impact-section-select-`),
    );

    const options = charities.map(({ name, emoji }, index) => {
        const isSelected = selectedCharityId === index;
        const optionId = `${selectCommonId}-option-${index}`;
        const onClick = () => {
            setSelectedCharityId(index);
        };

        return (
            <button
                key={optionId}
                type="button"
                tabIndex={isSelected ? -1 : undefined}
                className={classnames(
                    styles.option,
                    isSelected && styles.selected,
                )}
                onClick={onClick}>
                <Typography color="grey">
                    <Emoji
                        className={styles.optionEmoji}
                        symbol={emoji}
                        label=""
                    />
                    {name}
                </Typography>
            </button>
        );
    });

    return <div className={styles.root}>{options}</div>;
};

export default SeeYourImpactSectionCharitySelect;
