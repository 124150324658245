import React, { FC } from 'react';

import Container from '../../../UI/Container/Container';
import Section from '../../../UI/Section/Section';
import Typography from '../../../UI/Typography/Typography';
import WaysToDonate from '../../../charity/WaysToDonate/WaysToDonate';

import * as styles from './WeOfferSection.module.scss';

const WeOfferSection: FC = () => (
    <Section className={styles.root}>
        <Container className={styles.container}>
            <Typography variant="display" responsive align="center">
                We offer two simple ways to donate
            </Typography>
            <div className={styles.waysToDonateWrapper}>
                <WaysToDonate />
            </div>
        </Container>
    </Section>
);

export default WeOfferSection;
