import React, { useRef } from 'react';

import Layout from '../components/layout/Layout/Layout';
import HomeSEO from '../components/pages/index/HomeSEO/HomeSEO';
import HeroSection from '../components/pages/index/HeroSection/HeroSection';
import VideoSection from '../components/pages/index/VideoSection/VideoSection';
import BenefitsSection from '../components/pages/index/BenefitsSection/BenefitsSection';
import SeeYourImpactSection from '../components/pages/index/SeeYourImpactSection/SeeYourImpactSection';
import WeOfferSection from '../components/pages/index/WeOfferSection/WeOfferSection';
import ComeAndMakeSection from '../components/pages/index/ComeAndMakeSection/ComeAndMakeSection';
import TestimonialsSection from '../components/pages/index/TestimonialsSection/TestimonialsSection';
import CharitiesSection from '../components/pages/index/CharitiesSection/CharitiesSection';

const Home = () => {
    const seeYourImpactSectionRef = useRef<HTMLElement>(null);

    return (
        <>
            <HomeSEO />
            <Layout>
                <HeroSection />
                <VideoSection />
                <BenefitsSection
                    nextSectionElementRef={seeYourImpactSectionRef}
                />
                <SeeYourImpactSection ref={seeYourImpactSectionRef} />
                <CharitiesSection />
                <TestimonialsSection />
                <WeOfferSection />
                <ComeAndMakeSection />
            </Layout>
        </>
    );
};

export default Home;
