import React, { FC } from 'react';
import Typography from '../../../UI/Typography/Typography';

import * as styles from './BenefitsSectionBenefitsListItem.module.scss';

import CheckIcon from '../../../../images/components/pages/index/BenefitsSectionBenefitsListItem/check-icon.svg';

export interface BenefitsSectionBenefitsListItemProps {
    header: string;
    description: string;
}

const BenefitsSectionBenefitsListItem: FC<
    BenefitsSectionBenefitsListItemProps
> = ({ header, description }) => {
    return (
        <li className={styles.root}>
            <div className={styles.iconWrapper}>
                <CheckIcon width={26} height={26} alt="check icon" />
            </div>
            <Typography className={styles.header} variant="h1">
                {header}
            </Typography>
            <Typography
                className={styles.description}
                variant="leadParagraph"
                paragraph>
                {description}
            </Typography>
        </li>
    );
};

export default BenefitsSectionBenefitsListItem;
