import React, { FC } from 'react';
import classnames from 'classnames';

import BenefitsSectionBenefitsListItem from '../BenefitsSectionBenefitsListItem/BenefitsSectionBenefitsListItem';

import * as styles from './BenefitsSectionBenefitsList.module.scss';

export interface BenefitsSectionBenefitsListProps {
    className: string;
}

const BenefitsSectionBenefitsList: FC<BenefitsSectionBenefitsListProps> = ({
    className,
}) => (
    <ul className={classnames(styles.root, className)}>
        <BenefitsSectionBenefitsListItem
            header="Feel-good"
            description="See your impact with snappy updates on the difference that you’re making"
        />
        <BenefitsSectionBenefitsListItem
            header="Affordable"
            description="Small donations that your bank balance will barely notice"
        />
        <BenefitsSectionBenefitsListItem
            header="Flexible"
            description="Set donation limits, pause or change your donations at anytime, in a few taps"
        />
    </ul>
);

export default BenefitsSectionBenefitsList;
