import React, { useState } from 'react';

import Container from '../../../UI/Container/Container';
import Section from '../../../UI/Section/Section';
import Typography from '../../../UI/Typography/Typography';
import SeeYourImpactSectionCharitySelect from '../SeeYourImpactSectionCharitySelect/SeeYourImpactSectionCharitySelect';
import SeeYourImpactSectionCharityVideo from '../SeeYourImpactSectionCharityVideo/SeeYourImpactSectionCharityVideo';

import { charities } from './charities';
import * as styles from './SeeYourImpactSection.module.scss';
import SignUpButton from '../../../UI/SignUpButton/SignUpButton';

const SeeYourImpactSection = React.forwardRef<HTMLElement>((_props, ref) => {
    const [selectedCharityId, setSelectedCharityId] = useState(0);

    return (
        <Section
            ref={ref}
            className={styles.root}
            label="see your impact section">
            <Container className={styles.container}>
                <div className={styles.content}>
                    <Typography variant="display" responsive>
                        See your impact
                    </Typography>
                    <Typography
                        className={styles.subtitle}
                        variant="leadParagraph">
                        Tap a charity to see the sort of updates you can expect.
                        Feels good, right?
                    </Typography>
                    <SeeYourImpactSectionCharitySelect
                        charities={charities}
                        selectedCharityId={selectedCharityId}
                        setSelectedCharityId={setSelectedCharityId}
                    />
                    <img
                        className={styles.charityNotificationImage}
                        alt="charity notification example"
                        src={charities[selectedCharityId].notificationImageSrc}
                    />
                    <SignUpButton className={styles.signUpButton} />
                </div>
                <div className={styles.charityVideoWrapper}>
                    <SeeYourImpactSectionCharityVideo
                        src={charities[selectedCharityId].videoSrc}
                    />
                </div>
            </Container>
        </Section>
    );
});

export default SeeYourImpactSection;
