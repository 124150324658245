import React, { FC, useEffect, useRef } from 'react';

import { isSafari } from '../../../../utils';

export interface VideoSectionVideoProps {
    src: string;
    className?: string;
}

const VideoSectionVideo: FC<VideoSectionVideoProps> = ({ className, src }) => {
    // https://medium.com/@BoltAssaults/autoplay-muted-html5-video-safari-ios-10-in-react-673ae50ba1f5
    const videoWrapperRef = useRef<HTMLDivElement>(null);
    const videoHTML = `<video class="${className}" playsinline autoplay loop muted src="${src}" />`;

    useEffect(() => {
        if (videoWrapperRef.current) {
            videoWrapperRef.current.innerHTML = videoHTML;
            if (isSafari()) {
                const player = videoWrapperRef.current
                    .children[0] as unknown as {
                    controls: boolean;
                    playsinline: boolean;
                    muted: boolean;
                    setAttribute: (attr: string, value: unknown) => void;
                    autoplay: boolean;
                    play: () => void;
                };

                if (player) {
                    player.controls = false;
                    player.playsinline = true;
                    player.muted = true;
                    player.setAttribute('muted', '');
                    player.autoplay = true;

                    setTimeout(() => {
                        player.play();
                    }, 0);
                }
            }
        }
    }, [videoHTML]);

    return <div ref={videoWrapperRef} />;
};

export default VideoSectionVideo;
